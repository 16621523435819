import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-scroll';

import { smoothLinkProps } from '@pagerland/common/src/utils';

import Box from '@pagerland/common/src/components/Box';
import Container from '@pagerland/common/src/components/Container';

import Typography from '@pagerland/common/src/components/Typography';
import Button from '@pagerland/common/src/components/Button';
import ParallaxBackground from '../../components/ParallaxBackground';

import data from '../../data';

const WelcomeCourses = ({
  name,
  background,
  title,
  header,
  homepage,
  cta,
  homepageCta,
  WrapperProps,
  InnerProps,
  ContainerProps,
  CaptionProps,
  TitleProps,
  HeaderProps,
  CtaProps,
}) => (
  <Box name={name} {...WrapperProps}>
    <ParallaxBackground {...background} />
    <Box {...InnerProps}>
      <Container {...ContainerProps}>
        <Box {...CaptionProps}>
          <Typography {...TitleProps} >{title}</Typography>
          <Typography {...HeaderProps} >{header}</Typography>
          {homepage ?
            <Button {...CtaProps} {...homepageCta} /> :
            <Button {...CtaProps} {...cta} />
          }
        </Box>
      </Container>
    </Box >
  </Box >
);

WelcomeCourses.propTypes = {
  name: PropTypes.string.isRequired,
  WrapperProps: PropTypes.object,
  InnerProps: PropTypes.object,
  ContainerProps: PropTypes.object,
  CaptionProps: PropTypes.object,
  TitleProps: PropTypes.object,
  HeaderProps: PropTypes.object,
  GridProps: PropTypes.object,
  CtaProps: PropTypes.object,
  FeatureProps: PropTypes.object,
  FeatureIconProps: PropTypes.object,
  FeatureTitleProps: PropTypes.object,
  title: PropTypes.node,
  price: PropTypes.node,
  background: PropTypes.object,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      IconProps: PropTypes.object,
      title: PropTypes.node,
    }),
  ),
  cta: PropTypes.object,
  homepageCta: PropTypes.object,
  homepage: PropTypes.string,
};

WelcomeCourses.defaultProps = {
  WrapperProps: {
    pt: {
      _: 0,
      lg: 750,
    },
    position: 'relative',
    zIndex: 1,
    overflow: 'hidden',
  },
  InnerProps: {
    pt: {
      _: 100,
      lg: -20,
    },
    pb: {
      _: 20,
      lg: 0,
    },
    position: {
      _: 'relative',
      lg: 'absolute',
    },
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    bg: 'rgba(0, 0, 0, 0.5)',
    zIndex: 2,
  },
  ContainerProps: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  CaptionProps: {
    maxWidth: 600,
  },
  TitleProps: {
    as: 'h1',
    variant: 'h1',
    color: 'gray.6',
    children: 'None'
  },
  HeaderProps: {
    variant: 'h4',
    color: 'gray.6',
    children: 'None'
  },
  CtaProps: {
    as: Link,
    ...smoothLinkProps,
    mt: 4,
    variant: 'brand',
    size: 'large',
  },
  FeatureProps: {
    flexBox: true,
    alignItems: 'center',
  },
  FeatureIconProps: {
    fontSize: 24,
    color: 'gray.6',
    mr: 2,
  },
  FeatureTitleProps: {
    variant: 'h4',
    color: 'gray.6',
  },
  ...data.welcomecourses,
};

export default WelcomeCourses;
