import Box from "@pagerland/common/src/components/Box";
import Card from "@pagerland/common/src/components/Card";
import Container from "@pagerland/common/src/components/Container";
import Grid from "@pagerland/common/src/components/Grid";
import Img from "gatsby-image"
import Typography from "@pagerland/common/src/components/Typography";
import TagAlt from "@pagerland/icons/src/line/TagAlt";
import PropTypes from "prop-types";
import React from "react";
import Fade from "react-reveal/Fade";
import data from "../../data";

export default function MontessoriCourses({
    name,
    data,
    title,
    header,
    WrapperProps,
    ContainerProps,
    TitleProps,
    HeaderProps,
    GridProps,
    CardProps,
    ImgProps,
    CaptionProps,
    ItemTitleProps,
}) {
    const properties = data.allMarkdownRemark.nodes
    console.log(properties)

    return (
        <Box name={name} {...WrapperProps}>
            <Container {...ContainerProps}>
                <Typography {...TitleProps}>{title}</Typography>
                <Typography {...HeaderProps}>{header}</Typography>

                <Grid {...GridProps}>
                    {properties.map((property, key) => (
                        <Fade key={key} cascade bottom duration={600} delay={100 * key}>
                            <Card {...CardProps} as="a" href={"/course/" + property.frontmatter.slug}>

                                <Img {...ImgProps} fluid={property.frontmatter.thumb.childImageSharp.fluid} />
                                <Box {...CaptionProps} >
                                    <Typography {...ItemTitleProps}>{property.frontmatter.title}</Typography>
                                </Box>
                            </Card>
                        </Fade>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}

MontessoriCourses.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.node,
    properties: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.node,
            price: PropTypes.node,
            features: PropTypes.arrayOf(
                PropTypes.shape({
                    IconProps: PropTypes.object,
                    title: PropTypes.node,
                })
            ),
            LinkProps: PropTypes.object,
            ImgProps: PropTypes.object,
        })
    ),
    WrapperProps: PropTypes.object,
    ContainerProps: PropTypes.object,
    TitleProps: PropTypes.object,
    HeaderProps: PropTypes.object,
    GridProps: PropTypes.object,
    CardProps: PropTypes.object,
    ImgProps: PropTypes.object,
    CaptionProps: PropTypes.object,
    PriceBadgeProps: PropTypes.object,
    PriceBadgeIconProps: PropTypes.object,
    ItemTitleProps: PropTypes.object,
    FeaturesGridProps: PropTypes.object,
    FeatureProps: PropTypes.object,
    FeatureIconProps: PropTypes.object,
    FeatureTitleProps: PropTypes.object,
};

MontessoriCourses.defaultProps = {
    ...data.montessoriItems,
    WrapperProps: {
        pb: {
            _: "auto",
            md: "auto",
            lg: "auto",
        },
    },
    TitleProps: {
        borderTopWidth: "1px",
        borderTopColor: "gray.4",
        pt: 40,
        pb: {
            _: 3,
            md: 4,
            lg: 5,
        },
        as: "h2",
        variant: "h2",
    },
    HeaderProps: {
        borderTopWidth: "1px",
        borderTopColor: "gray.4",
        pt: 0,
        pb: {
            _: 3,
            md: 4,
            lg: 5,
        },
        as: "h5",
    },
    GridProps: {
        gridTemplateColumns: {
            _: "1fr",
            lg: "repeat(4, 2fr)",
        },
        gridGap: "32px",
    },
    CardProps: {
        p: 0,
        position: "relative",
        display: "block",
    },
    ImgProps: {
        width: "100%",
        display: "block",
    },
    CaptionProps: {
        py: 24,
        px: 4,
    },
    PriceBadgeProps: {
        position: "absolute",
        left: 0,
        top: 16,
    },
    PriceBadgeIconProps: {
        icon: TagAlt,
        fontSize: 24,
    },
    ItemTitleProps: {
        as: "span",
        variant: "h4",
        color: "#000",
        mb: 24,
    },
    FeaturesGridProps: {
        mt: 4,
        maxWidth: 320,
        gridTemplateColumns: "repeat(2, 1fr)",
        gridColumnGap: "32px",
        gridRowGap: "8px",
    },
    FeatureProps: {
        flexBox: true,
        alignItems: "center",
    },
    FeatureIconProps: {
        fontSize: 24,
        mr: 2,
        color: "#000",
    },
    FeatureTitleProps: {
        variant: "small",
        color: "gray.1",
    },
};

//export default MontessoriCourses;
