import Box from "@pagerland/common/src/components/Box";
import Container from "@pagerland/common/src/components/Container";
import Slider from "@pagerland/common/src/components/Slider";
import Typography from "@pagerland/common/src/components/Typography";
import PropTypes from "prop-types";
import React from "react";
import Fade from "react-reveal/Fade";
import Avatar from "../../components/Avatar";
import data from "../../data";

const Parents = ({
  name,
  title,
  comments,
  WrapperProps,
  InnerProps,
  TitleProps,
  SliderProps,
  SlideProps,
  QuoteProps,
  QuoteAuthorProps,
  AvatarProps,
  LiquidProps,
}) => (
  <Box {...WrapperProps} name={name}>
    <Container>
      <Box {...InnerProps}>
        <Fade bottom cascade duration={600}>
          <Typography {...TitleProps}>{title}</Typography>
          <Slider {...SliderProps}>
            {comments?.map((comment, key) => (
              <div key={key}>
                <Box {...SlideProps}>
                  <Avatar {...AvatarProps} {...comment.AvatarProps} />
                  <div>
                    <Typography {...QuoteProps}>{comment.text}</Typography>
                    <Typography {...QuoteAuthorProps}>
                      {comment.author}
                    </Typography>
                  </div>
                </Box>
              </div>
            ))}
          </Slider>
        </Fade>
      </Box>
    </Container>
  </Box>
);

Parents.propTypes = {
  /**
   * Name of container, can be used for anchors
   */
  name: PropTypes.string.isRequired,
  /**
   * Title text
   */
  title: PropTypes.node,
  /**
   * List of client comments
   */
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Author of the comment
       */
      author: PropTypes.node,
      /**
       * Comment text
       */
      text: PropTypes.node,
      /**
       * Object with props for Avatar component.
       * @See @pagerland/common/src/components/Avatar
       */
      AvatarProps: PropTypes.object,
    })
  ),
  /**
   * Main wrapper props
   * @See @pagerland/common/src/components/Box
   */
  WrapperProps: PropTypes.object,
  /**
   * Inner box props
   * @See @pagerland/common/src/components/Box
   */
  InnerProps: PropTypes.object,
  /**
   * Title text props
   * @See @pagerland/common/src/components/Typography
   */
  TitleProps: PropTypes.object,
  /**
   * Slider component props
   * @See @pagerland/common/src/components/Slider
   */
  SliderProps: PropTypes.object,
  /**
   * Single slide box props
   * @See @pagerland/common/src/components/Box
   */
  SlideProps: PropTypes.object,
  /**
   * Comment content text props
   * @See @pagerland/common/src/components/Typography
   */
  QuoteProps: PropTypes.object,
  /**
   * Author text props
   * @See @pagerland/common/src/components/Typography
   */
  QuoteAuthorProps: PropTypes.object,
  /**
   * Object with props for Avatar component.
   * @See @pagerland/common/src/components/Avatar
   */
  AvatarProps: PropTypes.object,
  /**
   * Object with props for Liquid component.
   */
  LiquidProps: PropTypes.object,
};

Parents.defaultProps = {
  WrapperProps: {
    py: {
      _: 0,
      lg: 0,
    },
  },
  InnerProps: {
    position: "relative",
    ml: {
      _: 1,
      lg: 0,
    },
    my: {
      _: 1,
      lg: 100,
    },
  },
  TitleProps: {
    as: "h2",
    variant: "h2",
    mb: {
      _: 1,
      lg: 20,
    },
  },
  SliderProps: {
    options: {
      dots: true,
      arrows: false,
    },
  },
  SlideProps: {
    flexBox: true,
    alignItems: "flex-start",
    pb: 3,
    flexDirection: {
      _: "column",
      lg: "row",
    },
  },
  QuoteProps: {
    variant: "quote2",
    color: "gray.1",
    as: "q",
  },
  QuoteAuthorProps: {
    as: "footer",
    variant: "h4",
    mt: 3,
    mb: 40,
  },
  AvatarProps: {
    fancy: true,
    mr: 120,
    ml: 80,
    mt: {
      _: 1,
      lg: 60,
    },
    order: {
      _: 1,
      lg: 0,
    },
  },
  LiquidProps: {
    top: "50%",
    marginTop: -250,
    zIndex: -1,
    left: -750,
    position: "absolute",
  },
  ...data.parents,
};

export default Parents;
