import Box from '@pagerland/common/src/components/Box';
import Container from '@pagerland/common/src/components/Container';
import Slider from "@pagerland/common/src/components/Slider";
import Typography from '@pagerland/common/src/components/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import Fade from 'react-reveal/Fade';
import data from '../../data';

const Reviews = ({
  name,
  reviews,
  WrapperProps,
  ContainerProps,
  CaptionProps,
  TitleProps,
  TextProps,
  SignProps,
  SliderProps,
}) => (
  <Box name={name} {...WrapperProps}>
    <Container {...ContainerProps}>
      <Box {...CaptionProps}>
        <Fade bottom cascade duration={600}>
          <Typography {...TitleProps}>"</Typography>
          <Slider {...SliderProps}>
            {reviews?.map((review, key) => (
              <div key={key}>
                <Typography {...TextProps}>{review.text}</Typography>
                <Typography {...SignProps}>{review.author}</Typography>
              </div>
            ))}
          </Slider>
        </Fade>
      </Box>
    </Container>
  </Box>
);

Reviews.propTypes = {
  name: PropTypes.string.isRequired,
  reviews: PropTypes.node,
  WrapperProps: PropTypes.object,
  ContainerProps: PropTypes.object,
  CaptionProps: PropTypes.object,
  TitleProps: PropTypes.object,
  TextProps: PropTypes.object,
  SignProps: PropTypes.object,
  SliderProps: PropTypes.object,
};

Reviews.defaultProps = {
  WrapperProps: {
    pt: {
      _: 'auto',
      md: 60,
    },
    pb: 80,
  },
  CaptionProps: {
    maxWidth: 'auto',
    mx: 'auto',
  },
  PostCaptionProps: {
    maxWidth: 'auto',
    mx: 'auto',
    py: {
      _: 'auto',
      md: 'auto',
      lg: 'auto',
    },
    pb: 80,
  },
  TitleProps: {
    as: 'h1',
    variant: 'h1',
    mb: 4,
    textAlign: 'center',
  },
  TextProps: {
    variant: 'body1',
    mb: 48,
    textAlign: 'center',
    color: 'gray.1',
  },
  SignProps: {
    variant: 'body1',
    mb: 48,
    textAlign: 'center',
    color: 'gray.1',
    fontWeight: 'bold'

  },
  SliderProps: {
    options: {
      dots: true,
      arrows: false,
    },
  },
  ...data.reviews,
};

export default Reviews;
