import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import { useSiteMetadata } from "../../hooks";
function Index({ description, lang, meta, title }) {
  const {
    title: siteTitle,
    subtitle: siteSubtitle,
    author: siteAuthor,
  } = useSiteMetadata();

  const metaDescription = description || siteSubtitle;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${siteTitle}`}
      meta={[
        {
          name: "description",
          content: metaDescription,
        },
        {
          property: "og:title",
          content: title,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:creator",
          content: siteAuthor.name,
        },
        {
          name: "twitter:title",
          content: title,
        },
        {
          name: "twitter:description",
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
}

Index.defaultProps = {
  lang: "en",
  meta: [],
  description: "",
};

Index.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Index;
