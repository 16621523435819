import Box from "@pagerland/common/src/components/Box";
import Button from "@pagerland/common/src/components/Button";
import Card from "@pagerland/common/src/components/Card";
import Container from "@pagerland/common/src/components/Container";
import Input from "@pagerland/common/src/components/Formik/Input";
import Typography from "@pagerland/common/src/components/Typography";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import Fade from "react-reveal/Fade";
import Reveal from "react-reveal/Reveal";
import Liquid from "../../components/Liquids/B";
import data from "../../data";

const Newsletter = ({
  WrapperProps,
  ContainerProps,
  LiquidProps,
  CardProps,
  TitleProps,
  TextProps,
  InputProps,
  ButtonProps,
  name,
  title,
  text,
  buttonText,
  inputPlaceholder,
  FormProps,
  LiquidComponent,
}) => (
  <Reveal ssrReveal ssrFadeout effect="liquid" duration={600}>
    <Box {...WrapperProps} name={name}>
      <Container {...ContainerProps}>
        <LiquidComponent {...LiquidProps} />
        <Card {...CardProps}>
          <Box {...FormProps}>
            <Formik
              validationSchema={data.newsletter.validationSchema}
              onSubmit={data.newsletter.onSubmit}
              initialValues={data.newsletter.fields.reduce(
                (acc, field) => ({
                  ...acc,
                  [field.name]: field.initialValue,
                }),
                {}
              )}
            >
              <Form data-netlify={true} name="newsletter">
                <Fade bottom cascade duration={600}>
                  <Typography {...TitleProps}>{title}</Typography>
                  <Typography {...TextProps}>{text}</Typography>

                  {data.newsletter.fields.map((field) => (
                    <Input
                      {...InputProps}
                      key={field.name}
                      {...field}
                      suffix={
                        <Button
                          variant="primary"
                          type="submit"
                          {...ButtonProps}
                        >
                          {buttonText}
                        </Button>
                      }
                    />
                  ))}
                </Fade>
              </Form>
            </Formik>
          </Box>
        </Card>
      </Container>
    </Box>
  </Reveal>
);

Newsletter.propTypes = {
  /**
   * Name of container, can be used for anchors
   */
  name: PropTypes.string.isRequired,
  /**
   * Component container props
   * @See @pagerland/common/src/components/Container
   */
  ContainerProps: PropTypes.object,
  /**
   * Main wrapper props
   * @See @pagerland/common/src/components/Box
   */
  WrapperProps: PropTypes.object,
  FormProps: PropTypes.object,
  /**
   * Props with liquid in background
   */
  LiquidProps: PropTypes.object,
  /**
   * Props for card wrapper
   */
  CardProps: PropTypes.object,
  /**
   * Title text props
   * @See @pagerland/common/src/components/Typography
   */
  TitleProps: PropTypes.object,
  /**
   * Main text props
   * @See @pagerland/common/src/components/Typography
   */
  TextProps: PropTypes.object,
  /**
   * Input email props
   * @See @pagerland/common/src/components/Input
   */
  InputProps: PropTypes.object,
  /**
   * Send Button props
   * @See @pagerland/common/src/components/Button
   */
  ButtonProps: PropTypes.object,
  /**
   * Title node of component
   */
  title: PropTypes.node,
  /**
   * Main description
   */
  text: PropTypes.node,
  /**
   * Email input placeholder
   */
  inputPlaceholder: PropTypes.string,
  /**
   * Text for button in form
   */
  buttonText: PropTypes.node,
  /**
   * Liquid component in background
   */
  LiquidComponent: PropTypes.elementType,
};

Newsletter.defaultProps = {
  WrapperProps: {
    overflow: "hidden",
    py: {
      _: 60,
      lg: 150,
    },
  },
  ContainerProps: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    pt: {
      _: 0,
      lg: 58,
    },
    pb: {
      _: 0,
      lg: 163,
    },
  },
  LiquidProps: {
    position: "absolute",
    maxWidth: {
      _: "none",
      lg: 770,
    },
    width: {
      _: "130%",
      lg: "100%",
    },
    zIndex: -1,
    transform: "scale(1, -1)",
  },
  CardProps: {
    borderRadius: {
      _: 30,
      lg: 90,
    },
    p: {
      _: 30,
      lg: 60,
    },
    width: "100%",
    maxWidth: 570,
  },
  TitleProps: {
    as: "h2",
    variant: "h2",
    mb: 2,
  },
  TextProps: {
    variant: "body2",
    color: "gray.1",
    borderRadius: "28px",
  },
  InputProps: {
    WrapperProps: {
      mt: 4,
      borderRadius: "28px",
    },
    InputWrapperProps: {
      borderRadius: "28px",
    },

    borderRadius: "28px",
  },
  ButtonProps: {
    bg: "brand",
    color: "gray.0",
    p: 0,
    mr: 0,
    mt: 0.5,
    width: 230,
    height: 50,
    lineHeight: "54px",
    textAlign: "center",
    fontSize: 18,
    borderRadius: "28px",
  },
  FormProps: {
    gridColumn: {
      md: "1 / 3",
      lg: "auto",
    },
  },
  LiquidComponent: Liquid,
  ...data.newsletter,
};

export default Newsletter;
